import { Remote, wrap } from "comlink";
import PacketsWorker, { IPacket, IPacketWorkerApi, PlotKey } from "./packet.worker"
import { IRealtimeDataMessage, IReceivedPacketTypes, PacketId } from "./common-types";
import { TimeWindow } from "../hooks/usePlayback";
import { ExportType } from "./hid-export";

const NUM_THREADS = navigator.hardwareConcurrency;

type Thread = {
    api: Remote<IPacketWorkerApi>;
    numTasks: number;
};

export type ServoPulseTransformFn = ( ( devicePosition: number) => number ) | undefined;

export type PacketRanges<I extends keyof IReceivedPacketTypes> = { [ K in keyof IReceivedPacketTypes[ I ] ]?: [ number, number ] };
type PacketRangesMap<I extends keyof IReceivedPacketTypes> = Map<I, PacketRanges<I>>;

const defaultPacketRanges = (): PacketRangesMap<keyof IReceivedPacketTypes> => {
    return new Map([
        [ PacketId.REALTIME_DATA, { motorSpeed: [ -10, 10 ] } ]
    ]);
};

let packetRangesMap: PacketRangesMap<keyof IReceivedPacketTypes> = defaultPacketRanges();

type RealtimeMetadata = {
    length: number;
    timeWindow: TimeWindow | undefined;
};

const defaultRealtimeMetadata = (): RealtimeMetadata => ({
    length: 0,
    timeWindow: undefined,
});

export let realtimeMetadata = defaultRealtimeMetadata();

export const getPacketRanges = <I extends keyof IReceivedPacketTypes>( packetId: I ): PacketRanges<I> => {
    if ( !packetRangesMap.has( packetId ) ) {
        packetRangesMap.set( packetId, {} );
    }

    return packetRangesMap.get( packetId )!;
};

export const updateRange = <I extends keyof IReceivedPacketTypes>( packet: IPacket<I> ) => {
    const ranges = getPacketRanges( packet.id );

    if ( packet.id === PacketId.REALTIME_DATA ) {
        realtimeMetadata.length++;
        if ( realtimeMetadata.timeWindow === undefined ) {
            realtimeMetadata.timeWindow = [ packet.timestampMs, packet.timestampMs ];
        } else {
            realtimeMetadata.timeWindow[ 1 ] = packet.timestampMs;
        }
    }

    for ( const key of Object.keys( packet.packet ) ) {
        const packetKey: keyof typeof packet.packet = key as keyof typeof packet.packet;
        let value = packet.packet[ packetKey ];
        if ( typeof value !== "number" ) {
            continue;
        }
        let range: [ number, number ] | undefined = ranges[ packetKey ];
        if ( range === undefined ) {
            range = [ value * 0.9 - 5e-2, value * 1.1 + 5e-2 ];
        } else if ( value > range[ 1 ] ) {
            range[ 1 ] = value;
        } else if ( value < range[ 0 ] ) {
            range[ 0 ] = value;
        }

        ranges[ packetKey ] = range;
    }
};

const threadPool: Thread[] = [];
for ( let i = 0; i < NUM_THREADS; i++ ) {
    threadPool.push( {
        api: wrap<IPacketWorkerApi>( new PacketsWorker() ),
        numTasks: 0
    } );
}

let threadIdx = 0;

const withThreadApi = async <T>( thread: Thread, fn: ( api: Remote<IPacketWorkerApi> ) => Promise<T> ): Promise<T> => {
    threadIdx = ( threadIdx + 1 ) % threadPool.length;
    thread.numTasks++;
    const val = await fn( thread.api );
    thread.numTasks--;
    return val;
};

// Cached battery voltage (Volts)
let batteryVoltage: number | undefined;

let servoPulseTransformFn: ServoPulseTransformFn;

const servoPulseTransform  = <I extends keyof IReceivedPacketTypes>( packet: IPacket<I> ) => {
    if ( packet.id === PacketId.REALTIME_DATA && servoPulseTransformFn !== undefined ) {
        const p = packet.packet as IRealtimeDataMessage;
        p.servoPulse = servoPulseTransformFn( p.servoPulse );
    }
};

export const packetThreadPoolApi = {
    handlePackets: async ( packets: IPacket<keyof IReceivedPacketTypes>[] ) => {
        for ( const packet of packets ) {
            servoPulseTransform( packet ); // Can modify packet
            updateRange( packet );
        }

        const promises = [];
        for ( let i = 0; i < threadPool.length; i++ ) {
            promises.push( withThreadApi( threadPool[ i ], api => api.handlePackets( packets ) ) );
        }
        await Promise.all( promises );

        if ( batteryVoltage === undefined ) {
            // If not cached, calc and cache
            const _batteryVoltage = await withThreadApi( threadPool[ threadIdx ], api => api.getBatteryVoltage() );
            if ( _batteryVoltage !== null ) {
                batteryVoltage = _batteryVoltage;
            }
        }
    },
    renderBitmap: async ( plotKeys: PlotKey[], timeWindow: TimeWindow, width: number, height: number, xHoverValue: number | undefined ) => {
        return await withThreadApi( threadPool[ threadIdx ], api => api.renderBitmap( plotKeys, timeWindow, width, height, xHoverValue, getPacketRanges( PacketId.REALTIME_DATA ) ) );
    },
    getLatest: async ( packetId: keyof IReceivedPacketTypes ) => {
        return await withThreadApi( threadPool[ threadIdx ], api => api.getLatest( packetId ) );
    },
    closestPacketBeforeTimestamp: async <I extends keyof IReceivedPacketTypes>( packetId: I, targetMs: number ) => {
        return await withThreadApi( threadPool[ threadIdx ], api => api.closestPacketBeforeTimestamp( packetId, targetMs ) );
    },
    closestPacketAfterTimestamp: async <I extends keyof IReceivedPacketTypes>( packetId: I, targetMs: number ) => {
        return await withThreadApi( threadPool[ threadIdx ], api => api.closestPacketAfterTimestamp( packetId, targetMs ) );
    },
    export: async ( packetId: keyof IReceivedPacketTypes, filename: string, exportType: ExportType, timeWindow: TimeWindow, downloadFn: ( filename: string, file: string ) => void ) => {
        return await withThreadApi( threadPool[ threadIdx ], api => api.export( packetId, filename, exportType, timeWindow, downloadFn ) );
    },
    getBatteryVoltage: (): number | undefined => {
        return batteryVoltage;
    },
    setServoPulseTransformFn: async ( fn: ServoPulseTransformFn ) => {
        servoPulseTransformFn = fn;
    },
    clear: async () => {
        const promises = [];
        for ( let i = 0; i < threadPool.length; i++ ) {
            promises.push( withThreadApi( threadPool[ i ], api => api.clear() ) );
        }
        await Promise.all( promises );
        packetRangesMap = defaultPacketRanges();
        realtimeMetadata = defaultRealtimeMetadata();
        batteryVoltage = undefined;
        servoPulseTransformFn = undefined;
    },
};
