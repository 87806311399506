import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
    ControlMode,
    InputMode,
    serializeSetUsbThrottleControlRequest,
} from "../../utils/common-types";
import { Button, HotkeyConfig, Intent, NumericInput, Slider, Spinner, useHotkeys } from "@blueprintjs/core";
import { Colors } from "../../design/colors";
import { throttle } from "../../utils/common";
import {
  LabelRendererFn,
  formatValueFromControlMode,
  getThrottleLabelRenderer,
  physicalToDevice,
} from "../../utils/throttle";
import { useElementSize } from "../../hooks/useElementSize";
import { REFRESH_MS } from "../../hooks/usePlayback";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGripVertical, faXmark } from "@fortawesome/free-solid-svg-icons";
import { hidSend } from "../../connections/hid";
import { useDevices } from "../../hooks/useDevices";
import {
    APPLY_CFG_TEXT,
    getPhysicalMax,
    getStepSize,
    isValid,
    useThrottleConfig,
} from "../../hooks/useThrottleConfig";
import { useMotorConfigurations } from "../../hooks/useMotorConfigurations";

const PULSE_INTERVAL_MS = 50;
const NEUTRAL_THROTTLE_POSITION = 0; // Physical units, but no dimensions need be specified if it's zero

export interface IThrottleWidgetProps {
    close: () => void;
}

// Not visible unless input mode is USB
export const Throttle = ( props: IThrottleWidgetProps ) => {
    const { close } = props;
    const { connectedDevice } = useDevices();
    const { throttleConfig } = useThrottleConfig();
    const { fetchConfigurations } = useMotorConfigurations(); // ToDo: after new protocol, can remove this hook
    const latestConfig = connectedDevice?.initialized ? connectedDevice.inputConfig : undefined;
    const [ throttleValue, _setThrottleValue ] = useState<number>( NEUTRAL_THROTTLE_POSITION ); // Physical units
    const throttleValueRef = useRef<number>( NEUTRAL_THROTTLE_POSITION );                       // Physical units
    const releasedValueRef = useRef<number | undefined>();                                      // Physical units
    const [ spinnerContainerRef, { width: spinnerContainerWidth, height: spinnerContainerHeight } ] = useElementSize();
    const [ manualValueStr, _setManualValueStr ] = useState<string>("");                        // Physical units
    const [ isCtrlKey, setIsCtrlKey ] = useState<boolean>( false );
    const pauseThrottleRef = useRef<boolean>( document.hidden );

    const setThrottleValue = useMemo( () => throttle( _setThrottleValue, REFRESH_MS ), [ _setThrottleValue ] );

    useEffect( () => {
        const onVisibilityChange = () => pauseThrottleRef.current = document.hidden;

        window.addEventListener( "visibilitychange", onVisibilityChange );

        return () => window.removeEventListener( "visibilitychange", onVisibilityChange );
    }, [] );

    // ToDo: after new protocol, can remove this hook; see related ToDo in useThrottleConfig.ts.
    //
    // There is an eslint rule exception because we really want to run this on
    // initial render and on device connect/disconnect but eslint thinks we
    // want to run it on initial render _and_ when the value of
    // fetchConfigurations changes.
    useEffect( () => {
        // throttleConfig will definitely be unavailable until configurations have been fetched.
        fetchConfigurations();
    }, [ connectedDevice ]); // eslint-disable-line react-hooks/exhaustive-deps

    const hotkeys: HotkeyConfig[] = useMemo( () => {
        let physicalMax = getPhysicalMax( throttleConfig );
        let stepSize = getStepSize( throttleConfig );

        if ( physicalMax === undefined || stepSize === undefined ) {
            return [];
        }

        return [
            {
                combo: "w",
                label: "Increase throttle",
                global: true,
                onKeyDown: throttle( () => {
                    if ( !isValid( throttleConfig ) ) return;
                    releasedValueRef.current = undefined;
                    setThrottleValue( v => {
                        if ( v === undefined || physicalMax === undefined || stepSize === undefined ) {
                            return v;
                        }
                        return Math.min( physicalMax, v + stepSize );
                    } );
                }, 16 ),
                preventDefault: true,
                stopPropagation: true
            },
            {
                combo: "s",
                label: "Decrease throttle",
                global: true,
                onKeyDown: throttle( () => {
                    if ( !isValid( throttleConfig ) ) return;
                    releasedValueRef.current = undefined;
                    setThrottleValue( v => {
                        if ( v === undefined || physicalMax === undefined || stepSize === undefined ) {
                            return v;
                        }
                        return Math.max( -physicalMax, v - stepSize );
                    } );
                }, 16 ),
                preventDefault: true,
                stopPropagation: true
            },
            {
                combo: "r",
                label: "Zero out throttle",
                global: true,
                onKeyDown: throttle( () => {
                    if ( !isValid( throttleConfig ) ) return;
                    releasedValueRef.current = throttleValueRef.current;
                    setThrottleValue( NEUTRAL_THROTTLE_POSITION );
                }, 16 ),
                preventDefault: true,
                stopPropagation: true
            },
        ];
    }, [ setThrottleValue, throttleConfig ] );


    useHotkeys( hotkeys );

    useEffect( () => {
        throttleValueRef.current = throttleValue;
    }, [ throttleValue ] );

    const sendPulse = useCallback( () => {
        const physicalMax = getPhysicalMax( throttleConfig );
        if ( latestConfig === undefined || connectedDevice === undefined || physicalMax === undefined || pauseThrottleRef.current ) {
            return;
        }

        const deviceThrottleValue = physicalToDevice( latestConfig.inputMode, latestConfig, physicalMax, throttleValueRef.current );
        hidSend(
            connectedDevice.handle,
            serializeSetUsbThrottleControlRequest(
                {
                    value: deviceThrottleValue,
                }
            ) )
            .catch( e => console.warn( "Failed to send throttle command:", e ) );
    }, [ connectedDevice, latestConfig, throttleConfig ] );

    useEffect( () => {
        sendPulse();
        const interval = setInterval( sendPulse, PULSE_INTERVAL_MS );
        return () => clearInterval( interval );
    }, [ sendPulse ] );

    const onChange = useCallback( ( value: number ) => {
        releasedValueRef.current = undefined;
        setThrottleValue( value );
    }, [ setThrottleValue ] );

    const onRelease = useCallback( () => {
        if ( !isCtrlKey ) {
            releasedValueRef.current = throttleValueRef.current;
            setThrottleValue( NEUTRAL_THROTTLE_POSITION );
        }
    }, [ isCtrlKey, setThrottleValue ] );

    const setManualValue = useCallback( ( value: number, valueStr: string ) => {
        _setManualValueStr( valueStr );
    }, [] );

    const updateWithManualValue = useCallback( () => {
        const manualValue = parseFloat( manualValueStr );
        const physicalMax = getPhysicalMax( throttleConfig );
        if ( physicalMax === undefined || !isFinite( manualValue ) ) {
            return;
        }

        // Clamp manualValue to within +/- physicalMax
        const value = Math.max( Math.min( manualValue, physicalMax ), -physicalMax );

        setThrottleValue( value );
        _setManualValueStr( "" );
        if ( value === 0 ) {
            releasedValueRef.current = throttleValueRef.current;
        } else {
            releasedValueRef.current = undefined;
        }
    }, [ manualValueStr, setThrottleValue, throttleConfig ] );

    if ( latestConfig === undefined || latestConfig.inputMode !== InputMode.USB ) {
        return null;
    }

    // These values are all for direct UI usage
    let physicalMax = 0;
    let stepSize = 0;
    let controlMode: ControlMode | undefined;
    let isInvalid = true;
    if ( throttleConfig && throttleConfig.invalidReason === undefined ) {
        stepSize = throttleConfig.stepSize;
        physicalMax = throttleConfig.physicalMax;
        controlMode = throttleConfig.controlMode;
        isInvalid = false;
    }

    let labelRenderer: LabelRendererFn = true;
    if ( controlMode !== undefined && stepSize > 0 ) {
        labelRenderer = getThrottleLabelRenderer( controlMode, stepSize );
    }

    if ( isInvalid ) {
        if ( throttleValue !== NEUTRAL_THROTTLE_POSITION ) {
            setThrottleValue( NEUTRAL_THROTTLE_POSITION );
        }
        labelRenderer = ( value ) => "–";
    }

    let spinnerValue: number;
    let spinnerIntent: Intent;
    let spinnerScale: string | undefined;
    const range = Math.max( physicalMax, 1e-4 ); // Prevent divide by zero
    if ( throttleValue < NEUTRAL_THROTTLE_POSITION ) {
        spinnerValue = -throttleValue / range;
        spinnerIntent = Intent.DANGER;
        spinnerScale = "-1 1";
    } else {
        spinnerValue = throttleValue / range;
        spinnerIntent = Intent.SUCCESS;
    }

    if ( releasedValueRef.current !== undefined ) {
        if ( releasedValueRef.current < NEUTRAL_THROTTLE_POSITION ) {
            spinnerIntent = Intent.DANGER;
            spinnerScale = "-1 1";
        } else {
            spinnerIntent = Intent.SUCCESS;
        }
    }

    const spinnerSize = Math.min( spinnerContainerHeight, spinnerContainerWidth ) * 0.9;

    return (
        <div
            style={{
                height: "100%",
                width: "100%",
                position: "relative",
                display: "flex",
                alignItems: "center",
                color: Colors.WHITE,
                padding: 16
            }}
        >
            {/* Drag and drop drag handle */}
            <FontAwesomeIcon
                icon={ faGripVertical }
                color={ Colors.WHITE }
                style={{ cursor: "pointer", position: "absolute", top: 0, left: 0}}
                size="lg"
            />
            <div style={{ color: Colors.WHITE, position: "absolute", top: 0, left: 0, padding: "0px 0px 0px 18px" }}>Throttle</div>
            <FontAwesomeIcon
                icon={ faXmark }
                color={ Colors.WHITE }
                style={{ position: "absolute", top: 0, right: 0, padding: "0px 4px", cursor: "pointer" }}
                onClick={ close }
                size="lg"
            />
            <div style={{ height: "100%", display: "flex", alignItems: "center" }} onMouseDown={ e => setIsCtrlKey( e.ctrlKey ) }>
                {
                    isInvalid || <Slider
                        className="throttle-slider"
                        min={ -physicalMax }
                        max={ physicalMax }
                        value={ formatValueFromControlMode(controlMode, throttleValue, stepSize) }
                        onChange={ onChange }
                        labelValues={ [ -physicalMax, NEUTRAL_THROTTLE_POSITION, physicalMax ] }
                        vertical={ true }
                        onRelease={ onRelease }
                        showTrackFill={ false }
                        disabled={ isInvalid }
                        labelRenderer={ labelRenderer }
                        stepSize={ isInvalid ? 1 : stepSize }
                    />
                }
            </div>
            <div ref={ spinnerContainerRef } style={{ flex: 1, display: "flex", height: "100%", alignItems: "center", justifyContent: "center", position: "relative" }}>
                <Spinner value={ spinnerValue } intent={ spinnerIntent } size={ spinnerSize } style={{ transform: "rotate(180deg)", scale: spinnerScale }} />
                <div style={{ position: "absolute" }}>
                    { isInvalid
                        ? <div className="bp4-callout bp4-intent-warning" style={{ textAlign: "center" }}>
                            <span style={{ color: "white" }}>
                              {
                                throttleConfig?.invalidReason ?? (
                                  "A motor configuration was not found for this device; " + APPLY_CFG_TEXT
                                )
                              }
                            </span>
                          </div>
                        : <NumericInput
                            placeholder={ isInvalid ? "–" : formatValueFromControlMode(controlMode, throttleValue, stepSize).toString() }
                            min={ -physicalMax }
                            max={  physicalMax }
                            style={{ width: spinnerSize * 0.5, borderRadius: 8, backgroundColor: Colors.LIGHT_GREEN, color: Colors.WHITE }}
                            clampValueOnBlur={ true }
                            onValueChange={ setManualValue }
                            value={ isInvalid ? "" : manualValueStr }
                            rightElement={ <Button icon="arrow-right" minimal={ true } intent="success" onClick={ updateWithManualValue } /> }
                            buttonPosition="none"
                            onKeyDown={ e => e.key === "Enter" ? updateWithManualValue() : undefined }
                            disabled={ isInvalid }
                        />
                    }
                </div>
            </div>
        </div>
    );
};
