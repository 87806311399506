import { useEffect, useState } from "react";
import {
    ControlMode,
    InputMode,
} from "../utils/common-types";
import {
  MIN_STEP,
  deviceToPhysical,
  isDeviceRangeValid,
} from "../utils/throttle";
import { Configuration } from "./useMotorConfigurations";
import { Device } from "./useDevices";
import {
    ServoPulseTransformFn,
    packetThreadPoolApi,
} from "../utils/packet-workers";
import { isThrottleUnitsEnabled } from "../utils/feature-flags";

// Throttle-related settings for a device. Either invalidReason is supplied or
// all fields are guaranteed to be valid.
export type ThrottleConfig = {
    id: string;
    invalidReason: string;
} | {
    id: string;
    invalidReason: undefined;
    controlMode: ControlMode;
    physicalMax: number;
    stepSize: number;
};

// Helpful accessor functions
export const isValid = (cfg?: ThrottleConfig): boolean =>
    ( cfg !== undefined && cfg.invalidReason === undefined );

export const getControlMode = (cfg?: ThrottleConfig): ControlMode | undefined =>
    ( cfg !== undefined && cfg.invalidReason === undefined ) ?  cfg.controlMode : undefined;

export const getPhysicalMax = (cfg?: ThrottleConfig): number | undefined =>
    ( cfg !== undefined && cfg.invalidReason === undefined ) ?  cfg.physicalMax : undefined;

export const getStepSize = (cfg?: ThrottleConfig): number | undefined =>
    ( cfg !== undefined && cfg.invalidReason === undefined ) ?  cfg.stepSize : undefined;


type Listener = () => void;
const _listeners: Listener[] = [];
const _notify =
    () =>
        _listeners.forEach((l) => {
            try {
                l()
            } catch ( e ) {
                console.error("Unhandled exception in listener:", e);
            }
        });

const _removeListener = ( l: Listener ) => {
    const index = _listeners.indexOf(l);
    if ( index === -1 ) return;
    _listeners.splice(index, 1);
};

let _motorConfigs: Configuration[] | undefined;

let _connectedDevice: Device | undefined;

let _throttleCfg: ThrottleConfig | undefined;

// should only be called by non-render code in useMotorConfigurations.ts
export const _updateMotorConfigs = ( configs: Configuration[] ) => {
    _motorConfigs = configs;
    handleUpdate();
    _notify();
};

// should only be called by non-render code in useDevices.ts
// Must be called in two situations:
//  * change to _connectedDeviceHandle
//  * change to the element of _devices referenced by _connectedDeviceHandle
export const _updateConnectedDevice = ( connectedDevice: Device | undefined ) => {
    _connectedDevice = connectedDevice;
    handleUpdate();
    _notify();
};

const handleUpdate = () => {
    _throttleCfg = undefined;
    if ( _motorConfigs !== undefined && _connectedDevice !== undefined && _connectedDevice.initialized ) {
        const activeConfigId = _connectedDevice.activeConfigurationId;
        const activeConfig = _motorConfigs.find((c) => c.id === activeConfigId);
        if ( activeConfig !== undefined ) {
            _throttleCfg = calcThrottleConfig( activeConfig, _connectedDevice);
        }
    }

    const inputConfig = _connectedDevice?.initialized ? _connectedDevice.inputConfig : undefined;

    let transformFn: ServoPulseTransformFn;
    if ( isThrottleUnitsEnabled() && inputConfig !== undefined && inputConfig.inputMode === InputMode.USB ) {
        // A transform function should only be set for USB input mode
        const physicalMax = getPhysicalMax( _throttleCfg );

        if ( _motorConfigs === undefined ) {
            // If there's a connected device but no motor configs, set
            // transformFn to something that always returns zero.
            // ToDo: once protocol supports getting all inputs to
            // calcThrottleConfig from the device, not the motor configuration,
            // there will be no need for this case.
            transformFn = ( devicePos: number ) => 0;
        } else if ( isValid( _throttleCfg ) && physicalMax !== undefined ) {
            // Normal case for USB input mode -- use physical units
            transformFn = ( devicePos: number ): number => {
                return deviceToPhysical( inputConfig.inputMode, inputConfig, physicalMax, devicePos )
            };
        }
    }
    packetThreadPoolApi.setServoPulseTransformFn( transformFn )
};

export const APPLY_CFG_TEXT = "please check and apply the configuration in order to use the throttle.";

const calcThrottleConfig = ( config: Configuration, connectedDevice: Device ): ThrottleConfig => {
    if ( !connectedDevice.initialized ) {
        // Unreachable; see CUID 86a3kekaj
        throw new Error( "Uninitialized connected device" );
    }

    const invalid = ( invalidReason: string ): ThrottleConfig =>
        ({ id: connectedDevice.mcuSerialNum, invalidReason });

    if ( connectedDevice.controlMode === undefined || connectedDevice.needToUpdate ) {
        return invalid( "A firmware update is required to use the throttle." );
    }

    // Prevents exceptions from being thrown by device range utility functions.
    const inputConfig = connectedDevice.inputConfig;
    if ( !isDeviceRangeValid( inputConfig ) ) {
        return invalid( "Device range is invalid; " + APPLY_CFG_TEXT );
    }

    // ToDo: once protocol supports getting these from device, do that instead
    // of reading from config. Then this hook does not need configurations at
    // all. See also ToDo in Throttle.tsx.
    let physicalMax = 0; // Maximum throttle value in either direction, in physical units
    let stepSize = 0; // Step size, in physical units
    switch ( connectedDevice.controlMode ) {
        case ControlMode.TORQUE:
            physicalMax = config?.data.powerConfig.motorCurrentLimit ?? physicalMax;
            stepSize = config?.data.inputConfig.ampsStep ?? stepSize;
            break;
        case ControlMode.SPEED:
            physicalMax = config?.data.controlConfig.motorMaxSpeed ?? physicalMax;
            stepSize = config?.data.inputConfig.rpmStep ?? stepSize;
            break;
        case ControlMode.VOLTAGE:
            return invalid( "Voltage control mode has been removed; " + APPLY_CFG_TEXT );
    }
    if ( !isFinite( stepSize ) || !isFinite( physicalMax ) || stepSize < MIN_STEP || ( physicalMax <= 0 || physicalMax < stepSize ) ) {
        return invalid( `Invalid physicalMax (${ physicalMax }) or stepSize (${ stepSize }); ${ APPLY_CFG_TEXT }` );
    }

    return {
        id: connectedDevice.mcuSerialNum,
        invalidReason: undefined,
        controlMode: connectedDevice.controlMode,
        physicalMax,
        stepSize,
    }
};

export const useThrottleConfig = () => {
    const [throttleConfig, setThrottleConfig] = useState<ThrottleConfig | undefined>( _throttleCfg );

    useEffect(() => {
        // Called after any of these events:
        //   * Motor configurations loaded/changed
        //   * Device connect/disconnect
        //   * Received certain config values back from device after applying config
        // A call means the throttle config for the currently connected device was recalculated.
        const onChange = () => {
            setThrottleConfig( _throttleCfg );
        };
        _listeners.push(onChange);

        return () => _removeListener(onChange);
    }, []);

    return {
        throttleConfig
    };
};
