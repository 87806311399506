import { useCallback, useEffect, useMemo, useState } from "react"
import {
    ControlMode,
    EncoderCalibStatus,
    EncoderDirection,
    IGetEncoderCalResultResponse,
    IReadInputConfigResponse,
    IReadSensorModeResponse,
    IWriteOpenLoopConfigRequest,
    IWriteSensorModeRequest,
    InputMode,
    PacketId,
    SensorMode,
    SmFwBuildType,
    deserialize,
    inputReportID,
    serializeGetBuildInfoRequest,
    serializeGetEncoderCalResultRequest,
    serializeHandshakeRequest,
    serializeReadControlModeRequest,
    serializeReadInputConfigRequest,
    serializeReadSensorModeRequest,
    serializeWriteControlConfigRequest,
    serializeWriteInputConfigRequest,
    serializeWriteOpenLoopConfigRequest,
    serializeWriteParametersRequest,
    serializeWritePowerConfigRequest,
    serializeWriteSensorModeRequest,
} from "../utils/common-types";
import { hidRequest } from "../connections/hid";
import { packetThreadPoolApi } from "../utils/packet-workers";
import { getDeviceRangeFromConfig } from "../utils/throttle";
import { deviceId } from "../connections/controller";
import {
    Configuration,
    DEFAULT_OPEN_LOOP_ACCEL_ELRADPS2,
    DEFAULT_SENSOR_MODE,
} from "./useMotorConfigurations";
import { _updateConnectedDevice } from './useThrottleConfig'; // See comment on that function for when it must be called.
import {
    CollectionId,
    DatabaseRecord,
    SensorsConfig,
    getRecord,
    setRecord,
} from "../utils/firestore";
import {
    isPositionLockEnabled,
    isSensorlessEnabled,
    isThrottleUnitsEnabled,
} from "../utils/feature-flags";
import {
    isPolePairCountValid,
    mechanicalToElectrical,
    versionLessThan,
} from "../utils/common";
import { MIN_FW_FOR_OPEN_LOOP } from "../utils/firmware-versions";

export const SALIENT_VENDOR_ID = 0x35ff;
export const HID_WARMUP_PERIOD = 500; // ms

// Lower timeout than default for the following because older f/w will never reply
const READ_SENSOR_MODE_TIMEOUT_MS = 300;
const ENCODER_CMD_TIMEOUT_MS = 300;
const READ_CONTROL_MODE_CMD_TIMEOUT_MS = 300;
const BUILD_INFO_TIMEOUT_MS = 50;

export type FwVersion = [ number, number, number ];
export const MIN_FW_VERSION: FwVersion = [ 1, 0, 0 ];

export type IEncoderState = IReadSensorModeResponse & IGetEncoderCalResultResponse;

export type DeviceBuildInfo = {
    year: number;
    month: number;
    day: number;
    increment: number;
    buildType: SmFwBuildType;
    dirty: boolean;
    gitCommitSHA1: number,
};

export type Device = {
    initialized: false;
    handle: HIDDevice;
} | {
    initialized: true;
    handle: HIDDevice;
    activeConfigurationId: string;
    mcuSerialNum: string;
    fwVersion: FwVersion;
    inputConfig: IReadInputConfigResponse;
    // controlMode is only missing if FW is too old
    // ToDo: When FW has a command to read entire control config, replace with controlConfig
    controlMode?: ControlMode,
    encoderState: IEncoderState; // Latest received state of encoder system on controller
    needToUpdate: boolean,
    buildInfo?: DeviceBuildInfo, // Stores firmware metadata of the device related to the build
    // buildInfo may be missing if the firmware is too old
};

let _devices: Device[] | undefined;
let _connectedDeviceHandle: HIDDevice | undefined;
const _listeners: ( () => void )[] = [];
const _notify = () => _listeners.forEach( l => l() );

// Duplicate of onInputReport in connection.ts?
const onInputReport = ( event: HIDInputReportEvent ) => {
    packetThreadPoolApi.handlePackets([{
        id: inputReportID( event.data ),
        packet: deserialize( event.data ),
        timestampMs: event.timeStamp,
    }]);
};

const _refresh = async () => {
    if ( !navigator['hid'] ) {
      return;
    }

    // If there are new devices returned by the browser WebHID API we haven't seen before, save them with initialized=false.
    // Note that this will remove disconnected devices from the _devices array.
    _devices = ( await navigator.hid.getDevices() )
        .filter( handle => handle.vendorId === SALIENT_VENDOR_ID )
        .map( handle => {
            return _devices?.find( d => d.handle === handle ) || {
                initialized: false,
                handle,
            };
        });

    // 1) Open device if not already open.
    // 2) Do handshake with device and read its input config.
    // 3) Try to get the device's active configuration ID from Firebase (OK if absent).
    // 4) Update _devices array with collected device information; now all are initialized.
    for ( let i = 0; i < _devices.length; i++ ) {
        const device: Device = _devices[ i ];
        let needToUpdate = false;
        let isNewDevice = false;
        if ( !device.initialized ) {
            isNewDevice = true;
            try {
                await device.handle.open();
            } catch ( e ) {
                console.warn( "_refresh, open device fail:", e );
                continue;
            }
        }

        // ToDo: if handle is _connectedDeviceHandle, get the return values of
        // these and use them to update new vars in useThrottleConfig. Those
        // new vars should override anything set in the configuration when
        // calculating ThrottleConfig, and then be cleared out whenever
        // _connectedDeviceHandle is reset to undefined.
        const handshake = await hidRequest( device.handle, PacketId.HANDSHAKE, serializeHandshakeRequest({}) );
        const fwVersion: FwVersion = [ handshake.versionMajor, handshake.versionMinor, handshake.versionBuild ];
        if ( isNewDevice ) {
            needToUpdate = await disableDeprecatedFeatures( device.handle, fwVersion );
        }
        const inputConfig = await hidRequest( device.handle, PacketId.READ_INPUT_CONFIG, serializeReadInputConfigRequest({}) );
        let controlMode: ControlMode | undefined;
        try {
            const result = await hidRequest( device.handle, PacketId.READ_CONTROL_MODE, serializeReadControlModeRequest({}), READ_CONTROL_MODE_CMD_TIMEOUT_MS );
            controlMode = result.controlMode;
        } catch ( e ) {
            console.warn( "Old firmware (pre-read-control-mode)?", e );
        }
        const encoderState = await readEncoderState( device.handle );
        const mcuSerialNum = deviceId( handshake );
        const gitCommitSHA1 = handshake.gitHash;
        let deviceMetadata: DatabaseRecord[ CollectionId.Devices ] | undefined;
        try {
            deviceMetadata = await getRecord( CollectionId.Devices, mcuSerialNum );
        } catch ( e ) {
            // Device record may not exist, or may have another user's configuration as its active configuration.
            console.warn( e );
        }

        let buildInfo;
        try {
            let buildInfoResponse = await hidRequest( device.handle, PacketId.GET_BUILD_INFO, serializeGetBuildInfoRequest({}), BUILD_INFO_TIMEOUT_MS );
            buildInfo = {
                year: buildInfoResponse.year,
                month: buildInfoResponse.month,
                day: buildInfoResponse.day,
                increment: buildInfoResponse.increment,
                buildType: buildInfoResponse.buildType,
                dirty: buildInfoResponse.dirty === 1,
                gitCommitSHA1,
            };
        } catch ( e ) {
            console.warn( "Build info request failed: ", e );
        }

        _devices[ i ] = {
            initialized: true,
            handle: device.handle,
            activeConfigurationId: deviceMetadata?.activeConfigurationId || "",
            mcuSerialNum,
            fwVersion,
            inputConfig,
            controlMode,
            encoderState,
            needToUpdate,
            buildInfo,
        };

        if ( device.handle === _connectedDeviceHandle ) {
            _updateConnectedDevice( _devices[ i ] );
        }
    }

    _notify();
};

// Disable any features that are disabled in the current environment but
// enabled in this controller's settings. This returns true if the user must
// upgrade their firmware in order to use important S-Lab features like the
// throttle.
const disableDeprecatedFeatures = async ( handle: HIDDevice, currentFwVersion: FwVersion ): Promise<boolean> => {
    const inputConfig = await hidRequest( handle, PacketId.READ_INPUT_CONFIG, serializeReadInputConfigRequest({}) );
    if ( !isPositionLockEnabled() && inputConfig.alwaysOn !== 0 ) {
        inputConfig.alwaysOn = 0; // Disable Position Lock on the device
        await hidRequest( handle, PacketId.WRITE_INPUT_CONFIG, serializeWriteInputConfigRequest( inputConfig ) );
    }

    let needToUpdate = false;
    const sensorModeAndEncoderCpr = await readSensorModeAndCpr( handle );
    const sensorMode = sensorModeAndEncoderCpr?.sensorMode;
    if ( !isSensorlessEnabled() ) {
        if ( sensorMode === undefined ) {
            // If it's too old to read the sensor mode, it's also too old for Open Loop, hence the `else if`
            needToUpdate = true;
        } else if ( sensorMode === SensorMode.SENSORLESS ) {
            if ( firmwareSupportsOpenLoop( handle, currentFwVersion ) ) {
                const sensorsConfig: IWriteOpenLoopConfigRequest & IWriteSensorModeRequest =
                    {
                        sensorMode: DEFAULT_SENSOR_MODE,
                        encoderCpr: sensorModeAndEncoderCpr?.encoderCpr ?? 0,
                        accelerationRamp: DEFAULT_OPEN_LOOP_ACCEL_ELRADPS2,
                    };
                await hidRequest( handle, PacketId.WRITE_OPEN_LOOP_CONFIG, serializeWriteOpenLoopConfigRequest(sensorsConfig), ENCODER_CMD_TIMEOUT_MS );
                await hidRequest( handle, PacketId.WRITE_SENSOR_MODE, serializeWriteSensorModeRequest(sensorsConfig), ENCODER_CMD_TIMEOUT_MS );
            } else {
                needToUpdate = true;
            }
        }
    }
    return needToUpdate;
};

// Returns true if the device's firmware version >= the minimum version that
// supports Open Loop, as determined by the entry for the device's product name
// in MIN_FW_FOR_OPEN_LOOP.
const firmwareSupportsOpenLoop = ( handle: HIDDevice, currentFwVersion: FwVersion ): boolean => {
    const productName = handle.productName.toLowerCase();
    const entry = Object.entries(MIN_FW_FOR_OPEN_LOOP).find( ([name, _]) => productName.includes(name.toLowerCase()));
    if ( entry === undefined ) {
        return false; // Assume unrecognized devices do not support Open Loop
    }
    const minFwVersion = entry[1];
    return !versionLessThan(currentFwVersion, minFwVersion);
};

// Read sensor mode and encoder CPR. Return undefined if firmware is too old to support the command.
const readSensorModeAndCpr = async ( handle: HIDDevice ): Promise<IReadSensorModeResponse | undefined> => {
    let response;
    try {
        response = await hidRequest( handle, PacketId.READ_SENSOR_MODE, serializeReadSensorModeRequest({}), READ_SENSOR_MODE_TIMEOUT_MS );
    } catch ( e ) {
        console.warn( "Old firmware (pre-encoder)? No reply to READ_SENSOR_MODE command", e );
    }
    return response;
};

// Reads encoder state from the device and returns it. The sensor mode at
// device connection time should be passed in if it is known, or else, it
// should be absent/undefined.
const readEncoderState = async ( handle: HIDDevice ): Promise<IEncoderState> => {
    let sensorModeAndEncoderCpr = await readSensorModeAndCpr( handle );
    if ( sensorModeAndEncoderCpr === undefined ) {
        sensorModeAndEncoderCpr = {
            sensorMode: SensorMode.SENSORLESS,
            encoderCpr: 0,
            encoderDirection: EncoderDirection.UNKNOWN,
            encoderOffset: NaN
        };
    }

    let encoderCalResult;
    try {
        encoderCalResult = await hidRequest( handle, PacketId.GET_ENCODER_CAL_RESULT, serializeGetEncoderCalResultRequest({}), ENCODER_CMD_TIMEOUT_MS );
    } catch ( e ) {
        console.warn( "Old firmware (pre-encoder)? No reply to GET_ENCODER_CAL_RESULT command", e );
        encoderCalResult = { status: EncoderCalibStatus.UNKNOWN, offset: NaN, direction: EncoderDirection.UNKNOWN};
    }
    return {
        ...sensorModeAndEncoderCpr,
        ...encoderCalResult,
    }
}

const _connect = ( device: Device ) => {
    _connectedDeviceHandle = device.handle;
    device.handle.addEventListener( "inputreport", onInputReport );
    const connectedDevice = _devices?.find( d => d.handle === _connectedDeviceHandle );
    if ( connectedDevice?.initialized ) {
        _updateConnectedDevice( connectedDevice );
    }
    _notify();
};

const _disconnect = () => {
    _connectedDeviceHandle?.removeEventListener( "inputreport", onInputReport );
    _connectedDeviceHandle = undefined;
    _updateConnectedDevice( undefined );
    _notify();
};

// Actually save the configuration to the Controller, then update device
// document in Firestore with the active configuration ID. Omitting `partial`
// argument or setting it to undefined causes everything to be saved. Calls
// _refresh, causing some parts of configuration to be read back from device,
// with listeners being notified afterward.
const _applyConfiguration = async ( device: Device, configuration: Configuration, partial?: Array<keyof typeof configuration.data> ) => {
    const updating = ( k: keyof typeof configuration.data ): boolean => partial === undefined || partial.indexOf(k) !== -1;
    try {
        if ( updating('inputConfig') ) {
          let inputConfig = configuration.data.inputConfig;
          if ( isThrottleUnitsEnabled() && configuration.data.inputConfig.inputMode === InputMode.USB ) {
              const deviceRange = getDeviceRangeFromConfig( configuration );
              inputConfig = {
                  ...inputConfig,
                  ...deviceRange,
              };
          }
          await hidRequest( device.handle, PacketId.WRITE_INPUT_CONFIG, serializeWriteInputConfigRequest( inputConfig ) );
        }
        if ( updating('powerConfig') ) await hidRequest( device.handle, PacketId.WRITE_POWER_CONFIG, serializeWritePowerConfigRequest( configuration.data.powerConfig ) );
        if ( updating('parameters') )  await hidRequest( device.handle, PacketId.WRITE_PARAMETERS, serializeWriteParametersRequest( configuration.data.parameters ) );
        if (updating('controlConfig')) await hidRequest( device.handle, PacketId.WRITE_CONTROL_CONFIG, serializeWriteControlConfigRequest(configuration.data.controlConfig) );
        if ( updating('sensorsConfig') )  {
            const sensorsConfig = getConvertedSensorsConfig( configuration );
            try {
                if (sensorsConfig.sensorMode === SensorMode.OPENLOOP) {
                    await hidRequest( device.handle, PacketId.WRITE_OPEN_LOOP_CONFIG, serializeWriteOpenLoopConfigRequest(sensorsConfig), ENCODER_CMD_TIMEOUT_MS );
                }
                await hidRequest( device.handle, PacketId.WRITE_SENSOR_MODE, serializeWriteSensorModeRequest(sensorsConfig), ENCODER_CMD_TIMEOUT_MS );
            } catch ( e ) {
                console.warn( "Old firmware (pre-encoder / pre-open-loop)? No reply to WRITE_OPEN_LOOP_CONFIG or WRITE_SENSOR_MODE commands", e );
            }
        }
        if ( device.initialized ) {
            await setRecord( CollectionId.Devices, device.mcuSerialNum, { firmwareVersion: device.fwVersion.join( "." ), activeConfigurationId: configuration.id } );
        }
    } finally {
        await _refresh();
    }
};

// Extract a SensorsConfig that is suitable for sending as payload of
// WRITE_OPEN_LOOP_CONFIG command.  Specifically, convert one field from
// mechanical to electrical units if it's possible to do so. Otherwise, set it
// to 0 for safety.
const getConvertedSensorsConfig = ( configuration: Configuration ): SensorsConfig => {
    if ( configuration.data.sensorsConfig === undefined ) {
        // Unreachable due to config upgrade logic in useMotorConfigurations
        throw new Error( "sensorsConfig cannot be undefined at this point!" );
    }
    let accelerationRamp = 0; // elrad/s^2
    const polePairCount = configuration.data.controlConfig.polePairCount;
    if ( isPolePairCountValid( polePairCount ) && configuration.data.sensorsConfig.accelerationRamp_mkradps2 !== undefined ) {
        accelerationRamp = mechanicalToElectrical( configuration.data.sensorsConfig.accelerationRamp_mkradps2, polePairCount );
    }
    return {
        ...configuration.data.sensorsConfig,
        accelerationRamp,
    };
};

navigator['hid'] && ( navigator.hid.onconnect = () => setTimeout( _refresh, HID_WARMUP_PERIOD ) );


const _pairDevice = async () => {
    const devices = await navigator.hid.requestDevice({
        filters: [{ vendorId: SALIENT_VENDOR_ID }]
    });

    if ( devices[ 0 ] !== undefined ) {
        await _refresh();
    }

    return devices[ 0 ];
};

const extractEncoderStateCurrentDev = (): IEncoderState | undefined => {
    const connectedDevice = _devices?.find( d => d.handle === _connectedDeviceHandle );
    if ( connectedDevice?.initialized ) {
        return connectedDevice.encoderState;
    }
}

export const useDevices = () => {
    const [ devices, setDevices ] = useState<typeof _devices>( _devices );
    const [ connectedDeviceHandle, setConnectedDeviceHandle ] = useState<typeof _connectedDeviceHandle>( _connectedDeviceHandle );
    const [ triggerDisconnectExport, setTriggerDisconnectExport ] = useState( false );
    const [ encoderState, setEncoderState ] = useState<IEncoderState | undefined>( extractEncoderStateCurrentDev() );
    const connectedDevice = _devices?.find( d => d.handle === connectedDeviceHandle );

    /**
     * Function to handle HID disconnect event
     * @param e The actual disconnect event.
     * Please note this does not trigger on clicking "disconnect" in the Device page, it triggers on an actual unplug of the device.
     */
    const onDisconnect = ( e: HIDConnectionEvent ) => {
        const device = _devices?.find( d => d.handle === e.device );
        if ( device !== undefined ) {
            _connectedDeviceHandle = undefined;
            _updateConnectedDevice( undefined );
        }
        setTriggerDisconnectExport(true);
        _refresh();
    };

    const applyConfiguration = useMemo( () => {
        if ( connectedDevice === undefined ) {
            return undefined;
        }

        return async ( configuration: Configuration, partial?: Array<keyof typeof configuration.data> ) => {
            await _applyConfiguration( connectedDevice, configuration, partial );
        };
    }, [ connectedDevice ] );

    useEffect( () => {
        const listener = () => {
            setDevices( _devices );
            setConnectedDeviceHandle( _connectedDeviceHandle );
            setEncoderState( extractEncoderStateCurrentDev() );
        };

        _listeners.push( listener );

        return () => {
            const index = _listeners.indexOf( listener );
            if ( index === -1 ) {
                return;
            }

            _listeners.splice( index, 1 );
        };
    }, [] );

    const _setEncoderState = useCallback( (encoderState: IEncoderState) => {
        if ( !connectedDevice ) {
            return;
        }
        let updated = false;
        const newDevices = _devices?.map((device: Device) => {
            if ( !device.initialized || device.handle !== _connectedDeviceHandle ) {
                return device;
            }
            updated = true;
            const modifiedDevice = {
                ...device,
                encoderState,
            }
            _updateConnectedDevice( modifiedDevice );
            return modifiedDevice;
        });
        if ( updated ) {
            _devices = newDevices;
            _notify();
        }
    }, [ connectedDevice ]);


    return {
        devices,
        connectedDevice,
        connect: _connect,
        disconnect: _disconnect,
        refresh: _refresh,
        pairDevice: _pairDevice,
        applyConfiguration,
        onDisconnect,
        triggerDisconnectExport,
        encoderState,
        setEncoderState: _setEncoderState,
    };
};
