import { FwVersion } from "../hooks/useDevices";
import { EncoderCalibStatus, IReceivedPacketTypes, FaultCode, SmFwBuildType } from "./common-types";

// Allows for compile-time validation of the exhaustiveness of switches on enums.
// If this is used for a numeric enum, use with StrictNumericEnumParam.
export const assertUnreachable = (x: never): never => {
    throw new Error("Unexpected code path!");
};

// Make it a compile time error to pass `number` into a function that takes a numeric Enum.
// Example:
//   export const fnThatTakesAnEnum = <Value extends YourEnumType>( yourEnumParam: StrictNumericEnumParam<YourEnumType, Value> ) => {
//     ...
//   }
// Kudos to UselessPickles for this: https://github.com/Microsoft/TypeScript/issues/26362#issuecomment-476018475
type StrictNumericEnumParam<Enum extends number, Param extends Enum> =
    true extends (
        { [key: number]: false } & { [P in Enum]: true }
    )[Enum] ? (
        true extends (
            { [key: number]: false } & { [P in Enum]: true }
        )[Param] ? Param : never
    ) : Enum;

export enum Env {
  Prod,
  Dev,
  Local,
};

// Get current environment from environment variable.
// Throws exception if invalid.
export const getEnv = (): Env => {
  switch ( process.env.REACT_APP_HOST_ENV ) {
      case "prod": return Env.Prod;
      case "dev": return Env.Dev;
      case "local": return Env.Local;
      default: throw new Error( `Invalid value for REACT_APP_HOST_ENV: ${ process.env.REACT_APP_HOST_ENV }` );
  }
};

export let appHost: string;
const env = getEnv();
switch ( env ) {
    case Env.Prod: appHost = "https://app.salientmotion.com"; break;
    case Env.Dev: appHost = "https://app-dev.salientmotion.com"; break;
    case Env.Local: appHost = "http://localhost:3000"; break;
    default: assertUnreachable( env );
}

export const isProd = () => getEnv() === Env.Prod;

// Click-Up 86a2ncw0q (migrating customers from app-dev back to app)
export const migrationDate = new Date('April 1, 2024 07:00:00 GMT-07:00');

type PacketKeysType = { [ I in keyof IReceivedPacketTypes ]: keyof IReceivedPacketTypes[ I ] };
export type PacketKeys<I extends keyof IReceivedPacketTypes> = PacketKeysType[ I ];

const capitalize = ( str: string ): string => {
    return str.charAt( 0 ).toUpperCase() + str.substring( 1 );
};

export const camelCaseToReadable = ( str: string ): string => {
    const words = str.match( /[A-Za-z][a-z]*/g ) || [];

    return words.map( capitalize ).join( " " );
};

export const downloadText = ( filename: string, text: string ) => {
    const a = document.createElement( "a" );
    a.style.display = "none";
    a.setAttribute( "href", "data:text/plain;charset=utf-8," + encodeURIComponent( text ) );
    a.setAttribute( "download", filename );

    document.body.appendChild( a );
    a.click();

    document.body.removeChild( a );
};

export const throttle = <T extends Function>( callback: T, ms: number, debounce: boolean = true ): T => {
    let lastTime: number = 0;
    let debounceTimeout: NodeJS.Timeout | undefined;
    return ( ( ...args: any[] ) => {
        const now = nowMs();
        if ( now - lastTime >= ms ) {
            clearTimeout( debounceTimeout );
            callback( ...args );
            lastTime = nowMs();
        } else if ( debounce ) {
            // Clear and reset debounce timeout
            clearTimeout( debounceTimeout );
            debounceTimeout = setTimeout( () => {
                callback( ...args );
                lastTime = nowMs();
            }, ms );
        }
    } ) as any;
};

export const nowMs = () => ( new Date() ).getTime();

export type ValueRange = [ number, number ];

export const moveValueRange = (
    [ windowLow, windowHigh ]: ValueRange,
    [ valueMin, valueMax ]: ValueRange,
    delta: number,
): ValueRange => {
    const currentWindowSize = windowHigh - windowLow;
    const newLow = Math.min( Math.max( windowLow + delta, valueMin ), valueMax - currentWindowSize );

    return [ newLow, newLow + currentWindowSize ];
}

export enum ControllerState {
    BOOT,
    CALIBRATION,
    L_R_DETECTION,
    ENCODER_DETECTION,
    STOPPED,
    RUNNING,
    TEST,
    UPDATING_FIRMWARE,
    FAULT,
}

export const controllerStateToString = <Value extends ControllerState>( controllerState: StrictNumericEnumParam<ControllerState, Value> ): string => {
    switch ( controllerState ) {
        case ControllerState.BOOT: return "Boot";
        case ControllerState.CALIBRATION: return "Calibration";
        case ControllerState.L_R_DETECTION: return "Parameter Detection";
        case ControllerState.ENCODER_DETECTION: return "Encoder Detection";
        case ControllerState.STOPPED: return "Stopped";
        case ControllerState.RUNNING: return "Running";
        case ControllerState.TEST: return "Test";
        case ControllerState.UPDATING_FIRMWARE: return "Updating Firmware";
        case ControllerState.FAULT: return "Fault";
        default: return assertUnreachable(controllerState);
    }
};

export const faultCodeToString = <Value extends FaultCode>( faultCode: StrictNumericEnumParam<FaultCode, Value> ): string => {
    switch ( faultCode ) {
        case FaultCode.NO_FAULT: return "None";
        case FaultCode.BAD_CURRENT_MEASURE: return "Hardware Fault";
        case FaultCode.OVERVOLTAGE: return "Under/Overvoltage";
        case FaultCode.OVERTEMPERATURE: return "Overtemperature";
        case FaultCode.OVERCURRENT: return "Overcurrent";
        case FaultCode.BAD_CONFIG: return "Config Corrupted";
        case FaultCode.GATE_DRIVE_UNDERVOLTAGE: return "Gate Drive Undervoltage";
        case FaultCode.LOGIC_UNDERVOLTAGE: return "Logic Undervoltage";
        case FaultCode.PARAM_DETECT_FAIL: return "Parameter Detection Failure";
        case FaultCode.NAN_OUTPUT: return "NaN Output";
        case FaultCode.BAD_PARAM: return "Bad Parameter";
        case FaultCode.INVALID_INPUT: return "Invalid Input";
        case FaultCode.ENCODER_CALIBRATION_FAILED: return "Encoder Calibration Failed";
        case FaultCode.GATE_DRIVE_OVERCURRENT: return "Gate Drive Overcurrent";
        case FaultCode.GATE_DRIVE_FAULT: return "Gate Drive Fault";
        case FaultCode.GATE_DRIVE_OVERTEMPERATURE_SHUTDOWN: return "Gate Drive Overtemp. Shutdown";
        case FaultCode.GATE_DRIVE_OVERTEMPERATURE_WARNING: return "Gate Drive Overtemp. Warning";
        case FaultCode.GATE_DRIVE_CHARGE_PUMP_VGLS_UNDERVOLTAGE: return "Gate Drive Charge Pump Vgls Undervoltage";
        case FaultCode.GATE_DRIVE_INIT_FAILED: return "Gate Drive Init Failed";
        default: return assertUnreachable(faultCode);
    }
};

export const encoderCalibrationStatusToString = ( status: EncoderCalibStatus ): string => {
    switch ( status ) {
        case EncoderCalibStatus.UNKNOWN: return "Not Calibrated";
        case EncoderCalibStatus.COMPLETE: return "Calibrated";
        case EncoderCalibStatus.ONGOING: return "Ongoing";
        case EncoderCalibStatus.FAILURE_INDEX_MISSING: return "Failure: Index Missing";
        case EncoderCalibStatus.FAILURE_POSITION_ERROR: return "Failure: Position Error";
        case EncoderCalibStatus.FAILURE_TIMEOUT: return "Failure: Timeout";
        case EncoderCalibStatus.FAILURE_ABORTED: return "Failure: Calibration Aborted";
    }
}

export const encoderCalibrationFailed = ( status: EncoderCalibStatus | undefined ): boolean => {
      return status === EncoderCalibStatus.FAILURE_INDEX_MISSING ||
             status === EncoderCalibStatus.FAILURE_POSITION_ERROR ||
             status === EncoderCalibStatus.FAILURE_TIMEOUT ||
             status === EncoderCalibStatus.FAILURE_ABORTED;
}

export const versionLessThan = ( v1: FwVersion, v2: FwVersion ): boolean => {
    for ( let i = 0; i < v1.length; i++ ) {
        if ( v1[ i ] < v2[ i ] ) {
            return true;
        } else if ( v1[ i ] > v2[ i ] ) {
            return false;
        }
    }

    return false;
}

export type Strings<T> = { [K in keyof T]: string };

export const strings = (obj: any) => {
  const stringsObj: any = {};
  for (const key of Object.keys(obj)) {
    stringsObj[key] = obj[key].toString();
  }
  return stringsObj;
};

export const rpmToRadps = ( rpm: number ): number => {
    return rpm * 2 * Math.PI / 60;
}

export const secondsToMilliseconds = ( seconds: number ): number => {
    return seconds * 1000;
}

// Must validate that polePairCount is a positive integer.
export const electricalToMechanical = ( elVal: number, polePairCount: number ): number => {
  return elVal / polePairCount;
};

export const mechanicalToElectrical = ( mechVal: number, polePairCount: number ): number => {
  return mechVal * polePairCount;
};

export const isPolePairCountValid = ( polePairCount: number ): boolean => {
  return ( Number.isInteger( polePairCount ) && polePairCount > 0 );
};

export const buildTypeIntToString = (buildTypeInt: number | undefined ): string => {
    if ( buildTypeInt === undefined || Number.isInteger(buildTypeInt) === false )
    {
        return "Unknown Build Type";
    }

    return SmFwBuildType[buildTypeInt];
};
