import { useCallback, useEffect, useMemo, useState } from "react";
import { faBolt, faChartLine, faCirclePlus, faGauge } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLocalStorage } from "usehooks-ts";

import { Colors } from "../../design/colors";
import { Gauge, IGaugeProps } from "./Gauge";
import { Throttle } from "./Throttle";
import { IPlotProps, Plot } from "./Plot";
import { DeviceUnitsThrottle } from "./DeviceUnitsThrottle";
import { isThrottleUnitsEnabled } from "../../utils/feature-flags";
import { MessageKeySelect } from "../MessageSelect";
import { IRealtimeDataMessage, InputMode, PacketId } from "../../utils/common-types";
import { PlaybackButton } from "../navbar/PlaybackButton";
import { PlaybackSlider } from "../navbar/PlaybackSlider";
import { useDevices } from "../../hooks/useDevices";
import { ExportButton } from "../navbar/ExportButton";
import { SortableWidgetsContext, SortableWidgetsItem } from "./sortable";

enum WidgetType {
    Plot,
    Gauge,
    Throttle,
}

export interface ICommonWidgetProps {
    close: () => void;
}

interface IWidgetProps {
    [ WidgetType.Plot ]: IPlotProps;
    [ WidgetType.Gauge ]: IGaugeProps;
    [ WidgetType.Throttle ]: undefined;
}

interface IWidget<T extends WidgetType> {
    widgetType: T;
    widgetProps: IWidgetProps[ T ];
}

export type Widget = IWidget<WidgetType>;

const widgetElement = ( _widget: Widget, close: () => void, addKey: ( packetKey: keyof IRealtimeDataMessage ) => void, removeKey: ( packetKey: keyof IRealtimeDataMessage ) => void ): JSX.Element => {
    switch ( _widget.widgetType ) {
        case WidgetType.Plot: {
            const widget = _widget as IWidget<WidgetType.Plot>;
            return <Plot { ...widget.widgetProps } addKey={ addKey } removeKey={ removeKey } />;
        }
        case WidgetType.Gauge: {
            const widget = _widget as IWidget<WidgetType.Gauge>;
            return <Gauge { ...widget.widgetProps } close={ close } />;
        }
        case WidgetType.Throttle: {
            if ( isThrottleUnitsEnabled() ) {
                return <Throttle close={ close } />;
            } else {
                return <DeviceUnitsThrottle close={ close } />;
            }
        }
    }
};

const widget = <T extends WidgetType>( widgetType: T, widgetProps: IWidgetProps[ T ] ): IWidget<T> => {
    return {
        widgetType,
        widgetProps,
    };
};

const AddWidgetButton = ( props: {
    addWidget: ( widget: Widget ) => void;
    enableThrottle: boolean;
    close: () => void;
} ) => {
    const [ isHovering, setIsHovering ] = useState<boolean>( false );
    const [ isHoveringPlot, setIsHoveringPlot ] = useState<boolean>( false );
    const [ isHoveringGauge, setIsHoveringGauge ] = useState<boolean>( false );
    const [ isHoveringThrottle, setIsHoveringThrottle ] = useState<boolean>( false );

    const [ gaugePopoverIsOpen, setGaugePopoverIsOpen ] = useState<boolean>( false );
    const [ plotPopoverIsOpen, setPlotPopoverIsOpen ] = useState<boolean>( false );

    const { addWidget, enableThrottle } = props;

    const _addWidget = useCallback( ( widget: Widget ) => {
        addWidget( widget );
        setIsHoveringPlot( false );
        setIsHoveringGauge( false );
        setIsHoveringThrottle( false );
        setIsHovering( false );
    }, [ addWidget ] );

    let throttleIcon = null;
    if ( enableThrottle ) {
        throttleIcon = (
            <div style={{ display: "flex", alignItems: "center" }}>
                <span style={{ color: Colors.WHITE, opacity: isHoveringThrottle ? 1 : 0.5, marginRight: 4 }}>THROTTLE</span>
                <FontAwesomeIcon
                    icon={ faBolt }
                    size="4x"
                    style={{ padding: 8, opacity: isHoveringThrottle ? 1 : 0.5 }}
                    color={ Colors.WHITE }
                    onMouseOver={ () => setIsHoveringThrottle( true ) }
                    onMouseLeave={ () => setIsHoveringThrottle( false ) }
                    onClick={ () => _addWidget( widget( WidgetType.Throttle, undefined ) ) }
                />
            </div>
        );
    }

    let icons = null;
    if ( isHovering || gaugePopoverIsOpen || plotPopoverIsOpen ) {
        icons = (
            <>
                { throttleIcon }
                <MessageKeySelect
                    packetId={ PacketId.REALTIME_DATA }
                    onSelect={ key => _addWidget( widget( WidgetType.Gauge, { packetKey: key } ) ) }
                    onOpening={ () => setGaugePopoverIsOpen( true ) }
                    onClose={ () => setGaugePopoverIsOpen( false ) }
                >
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <span style={{ color: Colors.WHITE, opacity: isHoveringGauge ? 1 : 0.5, marginRight: 4 }}>GAUGE</span>
                        <FontAwesomeIcon
                            icon={ faGauge }
                            size="4x"
                            style={{ padding: 8, opacity: isHoveringGauge ? 1 : 0.5 }}
                            color={ Colors.WHITE }
                            onMouseOver={ () => setIsHoveringGauge( true ) }
                            onMouseLeave={ () => setIsHoveringGauge( false ) }
                        />
                    </div>
                </MessageKeySelect>
                <MessageKeySelect
                    packetId={ PacketId.REALTIME_DATA }
                    onSelect={ key => _addWidget( widget( WidgetType.Plot, { packetKeys: [ key ] } ) ) }
                    exclude={ [ "controllerState", "faultCode" ] }
                    onOpening={ () => setPlotPopoverIsOpen( true ) }
                    onClose={ () => setPlotPopoverIsOpen( false ) }
                >
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <span style={{ color: Colors.WHITE, opacity: isHoveringPlot ? 1 : 0.5, marginRight: 4 }}>CHART</span>
                        <FontAwesomeIcon
                            icon={ faChartLine }
                            size="4x"
                            style={{ padding: 8, opacity: isHoveringPlot ? 1 : 0.5 }}
                            color={ Colors.WHITE }
                            onMouseOver={ () => setIsHoveringPlot( true ) }
                            onMouseLeave={ () => setIsHoveringPlot( false ) }
                        />
                    </div>
                </MessageKeySelect>
            </>
        );
    }

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                cursor: "pointer",
                opacity: isHovering ? 1 : 0.5,
                alignItems: "flex-end",
            }}
            onMouseOver={ () => setIsHovering( true ) }
            onMouseLeave={ () => setIsHovering( false ) }
        >
            { icons }
            <FontAwesomeIcon
                icon={ faCirclePlus }
                size="4x"
                color={ Colors.WHITE }
                style={{ padding: 8 }}
            />
        </div>
    );
};

const MIN_CARD_WIDTH = 550;

const hasThrottleWidget = ( widgets: Widget[] ): boolean => widgets.findIndex( w => w.widgetType === WidgetType.Throttle ) !== -1;

const defaultWidgets: Widget[] = [
    widget( WidgetType.Throttle, undefined ),
    widget( WidgetType.Plot, { packetKeys: [ "motorSpeed", "servoPulse" ] } ),
    widget( WidgetType.Plot, { packetKeys: [ "batteryVoltage" ] } ),
    widget( WidgetType.Plot, { packetKeys: [ "uptimeMs" ] } ),
    widget( WidgetType.Plot, { packetKeys: [ "motorCurrent", "batteryCurrent" ] } ),
    widget( WidgetType.Gauge, { packetKey: "inverterTemp" } ),
    widget( WidgetType.Gauge, { packetKey: "controllerState" } ),
];

const PANEL_PADDING = 16;

// This is necessary to handle renamed widgets not matching with local storage.
const renameWidgets = ( ws: Widget[] ): Widget[] => {
    let modified = false;
    let newWidgets = ws.map( ( _widget ) => {
        switch ( _widget.widgetType ) {
            case WidgetType.Gauge: {
                const widget = _widget as IWidget<WidgetType.Gauge>;
                if ( widget?.widgetProps?.packetKey as string === "mosfetTemp" ) {
                    modified = true;
                    return { widgetType: widget.widgetType, widgetProps: { ...widget.widgetProps, packetKey: 'inverterTemp' } } as Widget;
                }
                return _widget;
            }
            case WidgetType.Plot: {
                const widget = _widget as IWidget<WidgetType.Plot>;
                if ( ( widget?.widgetProps?.packetKeys as string[] ).indexOf( "mosfetTemp" ) !== -1 ) {
                    modified = true;
                    let packetKeys = ( widget.widgetProps.packetKeys as string[] ).map( ( packetKey ) => packetKey === "mosfetTemp" ? "inverterTemp" : packetKey);
                    return { widgetType: widget.widgetType, widgetProps: { ...widget.widgetProps, packetKeys } } as Widget;
                }
                return _widget;
            }
            default:
                return _widget;
        }
    });
    return modified ? newWidgets : ws;
};

export const WidgetPanel = () => {
    const { connectedDevice } = useDevices();
    const [ containerWidth, setContainerWidth ] = useState<number>( 1 );
    const [ sliderHeight, setSliderHeight ] = useState<number>( 1 );
    const panelWidth = Math.max( containerWidth - PANEL_PADDING * 2, 1 );
    const enableThrottle = connectedDevice !== undefined && connectedDevice.initialized && connectedDevice.inputConfig.inputMode === InputMode.USB;

    const [ widgets, setWidgets ] = useLocalStorage<Widget[]>( "dash", defaultWidgets );

    const [ widgetWidth, widgetHeight ] = useMemo( () => {
        const numColumns = Math.floor( panelWidth / Math.min( MIN_CARD_WIDTH, panelWidth ) );
        const _widgetWidth = Math.floor( panelWidth / numColumns );
        const _widgetHeight = Math.floor( _widgetWidth / 1.5 );

        return [ _widgetWidth, _widgetHeight ]
    }, [ panelWidth ] );

    // This is necessary to handle renamed widgets not matching with local storage.
    useEffect(() => {
        let newWidgets = renameWidgets( widgets );
        if ( newWidgets !== widgets ) {
            setWidgets( newWidgets );
        }
    }, [ widgets, setWidgets ]);

    const addWidget = useCallback( ( widget: Widget ) => {
        setWidgets( ws => {
            if ( widget.widgetType === WidgetType.Throttle && hasThrottleWidget( ws ) ) {
                return ws;
            }

            return [ ...ws, widget ];
        } );
    }, [ setWidgets ] );

    const removeWidget = useCallback( ( index: number ) => {
        setWidgets( ws => ws.slice( 0, index ).concat( ws.slice( index + 1 ) ) );
    }, [ setWidgets ] );

    const addKey = useCallback( ( index: number, key: keyof IRealtimeDataMessage ) => {
        setWidgets( ws => {
            const _widget = ws[ index ];
            if ( _widget === undefined || _widget.widgetType !== WidgetType.Plot ) {
                return ws;
            }

            const widget = _widget as IWidget<WidgetType.Plot>;

            return [ ...ws.slice( 0, index ), { ...widget, widgetProps: { ...widget.widgetProps, packetKeys: [ ...widget.widgetProps.packetKeys, key ] } } as unknown as Widget, ...ws.slice( index + 1 ) ];
        } );
    }, [ setWidgets ] );

    const removeKey = useCallback( ( index: number, key: keyof IRealtimeDataMessage ) => {
        setWidgets( ws => {
            const _widget = ws[ index ];
            if ( _widget === undefined || _widget.widgetType !== WidgetType.Plot ) {
                return ws;
            }

            const widget = _widget as IWidget<WidgetType.Plot>;

            const keyIndex = widget.widgetProps.packetKeys.indexOf( key );
            if ( keyIndex === -1 ) {
                return ws;
            }

            if ( widget.widgetProps.packetKeys.length === 1 ) {
                return ws.slice( 0, index ).concat( ws.slice( index + 1 ) );
            }

            return [
                ...ws.slice( 0, index ),
                { ...widget,
                    widgetProps: {
                        ...widget.widgetProps,
                        packetKeys: [ ...widget.widgetProps.packetKeys.slice( 0, keyIndex ), ...widget.widgetProps.packetKeys.slice( keyIndex + 1 ) ]
                    }
                } as unknown as Widget,
                ...ws.slice( index + 1 )
            ];
        } );
    }, [ setWidgets ] );

    const widgetCards = useMemo( () => {
        const cards: JSX.Element[] = [];
        const marginSize = 8;
        const adjustedWidgetWidth = widgetWidth - marginSize * 2; // subtract margin on both sides

        const cardStyle: React.CSSProperties = {
            display: "flex",
            borderRadius: 10,
            backgroundColor: Colors.DARK_GREEN,
            boxShadow: "1px 2px 9px #222222",
            flexDirection: "column",
            margin: `${marginSize}px`,
        };

        for ( const [ i, widget ] of widgets.entries() ) {
            if ( widget.widgetType === WidgetType.Throttle && !enableThrottle ) {
                continue;
            }

            cards.push(
                <SortableWidgetsItem key={ i } id={ i.toString() } style={{ width: adjustedWidgetWidth, height: widgetHeight, padding: 16, ...cardStyle}}>
                    { widgetElement( widget, removeWidget.bind( null, i ), addKey.bind( null, i ), removeKey.bind( null, i ) ) }
                </SortableWidgetsItem>
            )
        }

        return cards;
    }, [ enableThrottle, widgets, widgetWidth, widgetHeight, removeWidget, addKey, removeKey ] );

    return (
        <div ref={ e => e === null ? undefined : setContainerWidth( e.clientWidth ) } style={{ display: "flex", padding: PANEL_PADDING, flex: 1 }}>
            <SortableWidgetsContext
                widgets={ widgets }
                setWidgets={ setWidgets }
            >
                <div
                    style={{
                        flex: 1,
                        display: "flex",
                        flexWrap: "wrap",
                        alignContent: "flex-start",
                        position: "relative",
                        paddingBottom: sliderHeight,
                    }}
                >
                    <div style={{ display: "flex", flexWrap: "wrap" }}>
                        { widgetCards }
                    </div>
                </div>
            </SortableWidgetsContext>
            <div style={{ position: "fixed", bottom: sliderHeight - 16, right: 16 }}>
                <AddWidgetButton addWidget={ addWidget } enableThrottle={ enableThrottle && !hasThrottleWidget( widgets ) } close={ removeWidget.bind( null, widgets.length ) } />
            </div>
            <div ref={ e => setSliderHeight( e?.clientHeight || 0 ) } style={{ position: "fixed", bottom: 0, right: 0, width: containerWidth }}>
                <div style={{ padding: 32 }}>
                    <div style={{ display: "flex", padding: 16, borderRadius: 8, backgroundColor: Colors.DARK_GREEN, boxShadow: "1px 2px 9px #222222" }}>
                        <div style={{ marginRight: 16, display: "flex", alignItems: "center" }}>
                            <ExportButton />
                            <div style={{ width: 8 }} />
                            <PlaybackButton />
                        </div>
                        <PlaybackSlider packetId={ PacketId.REALTIME_DATA } />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default IWidget;
